import axios from 'axios';
import { authPlugin } from '@/auth';

import constants from '@/store/constants';
import quotePayloadFactory from '@/store/quotePayloadFactory';

const {
  SharedComparisonModule: SCMod,
} = constants;

const Endpoint = `${process.env.VUE_APP_API}/SharedComparisons`;

const Mutations = {
  SetData: 'SetData',
  SetIsLoading: 'SetIsLoading',
};

const initialState = () => ({
  [SCMod.StateVars.Data]: null,
  [SCMod.StateVars.IsLoading]: false,
});

const actions = {
  [SCMod.ActionLoad]: async (context, payload) => {
    if (!payload?.id) {
      throw Error('"Payload.id" is required');
    }

    context.commit(Mutations.SetIsLoading, true);
    try {
      const { data } = await axios.get(`${Endpoint}/${payload.id}`);
      context.commit(Mutations.SetData, data);
    } catch (error) {
      throw new Error('Could not load Shared Comparisons');
    } finally {
      context.commit(Mutations.SetIsLoading, false);
    }
  },
  [SCMod.ActionCreate]: async (context, payload) => {
    const {
      dependants,
      email,
      income,
      lateJoinerPenalty,
      message,
      selectedOptions,
    } = payload;
    if (!dependants) {
      throw Error('"Payload.dependants" is required');
    }
    if (!lateJoinerPenalty) {
      throw Error('"Payload.lateJoinerPenalty" is required');
    }
    if (!selectedOptions) {
      throw Error('"Payload.selectedOptions" is required');
    }

    context.commit(Mutations.SetIsLoading, true);
    try {
      const token = await authPlugin.getTokenSilently();

      const { data } = await axios.post(
        Endpoint,
        {
          emails: [email],
          message,
          quoteInput: quotePayloadFactory.Generate(
            lateJoinerPenalty,
            income,
            dependants,
          ),
          selectedOptions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return data;
    } catch (error) {
      throw new Error('Could not create Shared Comparison');
    } finally {
      context.commit(Mutations.SetIsLoading, false);
    }
  },
};

const SetStateVarToValue = (stateVar) => (stateP, value) => {
  const localState = stateP;
  localState[stateVar] = value;
};

const mutations = {
  [Mutations.SetData]: SetStateVarToValue(SCMod.StateVars.Data),
  [Mutations.SetIsLoading]: SetStateVarToValue(SCMod.StateVars.IsLoading),
};

const state = initialState();

export default {
  actions,
  mutations,
  namespaced: true,
  state,
};
