import constants from '@/store/constants';

const {
  ReportModule: RM,
} = constants;

const SetStateVarToValue = (stateVar) => (stateP, value) => {
  const localState = stateP;
  localState[stateVar] = value;
};

const mutations = {
  [RM.Mutations.SetCollapsedSlabs]: SetStateVarToValue(RM.StateVars.CollapsedSlabs),
  [RM.Mutations.SetFocusedOption]: SetStateVarToValue(RM.StateVars.FocusedOption),
};

const initialState = () => ({
  [RM.StateVars.CollapsedSlabs]: {
    maternity: true,
    dayToDayExpenses: true,
    specialisedRadiology: true,
    emergencyServices: true,
    scopes: true,
    chronicCover: true,
    coPayments: true,
    hospitalCover: true,
    prosthesis: true,
    alternatives: true,
    dayToDaySearch: true,
    lineItems: true,
  },
  [RM.StateVars.FocusedOption]: null,
});

const state = initialState();

export default {
  mutations,
  namespaced: true,
  state,
};
