<template>
  <nav class="navbar navbar-dark bg-primary">
    <span class="navbar-brand py-0">
      <img alt="FOXi Logo" class="logo" src="/img/horizontal-logo.png">
    </span>

    <slot v-if="!this.$auth.loading.value && $route.name !== 'Comparison'">
      <p-button v-if="!$auth.isAuthenticated.value" @click="login" label="Log in" />
      <div style="float: right" v-else>
        <p-button class="p-button-link text-dark" @click="requestTour">
          Tour
        </p-button>
        <p-button class="p-button-link text-dark" @click="logout">
          Log out
        </p-button>
      </div>
    </slot>
  </nav>
  <router-view />
</template>

<script>
import { useStore } from 'vuex';

import Constants from '@/store/constants';

export default {
  name: 'App',
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  setup() {
    const store = useStore();

    const requestTour = () => {
      store.commit(
        `${Constants.ToursModule.Name}/${Constants.ToursModule.Mutations.SetHasTourRun}`,
        false);
    };

    return {
      requestTour,
    };
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
}

#app {
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
}

.h-100-without-navbar {
  height: calc(100% - 56px);
}
</style>

<style lang="scss">
@import '@/assets/styles/_variables.scss';
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: $primaryColor;
  color: $darkColor;
}

$logoSquareLength: 100px;

.logo {
  max-width: $logoSquareLength;
}
</style>
