export default {
  Generate: (lateJoinerPenalty, income, dependants) => ({
    lateJoinerPenalty,
    income,
    adultDependants: dependants
      .filter((d) => d.type !== 'Child')
      .map((d) => ({
        lateJoinerPenalty: d.adultLateJoiner,
      })),
    childDependants: dependants
      .filter((d) => d.type === 'Child')
      .map((d) => ({
        age: d.childAge,
        isStudent: !!d.childIsStudent,
      })),
  }),
};
