import axios from 'axios';
import constants from '@/store/constants';
import { authPlugin } from '@/auth';

const Endpoint = `${process.env.VUE_APP_API}/OptionsDetails`;

const {
  Mutations,
  OptionDetailsModule: ODM,
} = constants;

const actions = {
  [ODM.ActionLoad]: async (context, payload) => {
    if (!payload) {
      throw Error('Parameter "payload" is Required');
    }

    const localData = context.state[ODM.StateVars.Data];
    const noInfoReadyElements = payload
      .filter((p) => !Object.keys(localData).some((ld) => ld === p));
    if (!noInfoReadyElements.length) {
      return;
    }

    const idsAsQueryString = `ids=${noInfoReadyElements.join('&ids=')}`;

    const token = await authPlugin.getTokenSilently();

    const { data } = await axios.get(
      `${Endpoint}?${idsAsQueryString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const spreadWithoutOptionId = ({ optionId, ...o }) => o;
    data.forEach((option) => {
      localData[option.optionId] = {
        ...spreadWithoutOptionId(option),
      };
    });
    context.commit(Mutations.SetData, localData);
  },
};

const initialState = () => ({
  [ODM.StateVars.Data]: {},
  [ODM.StateVars.IsLoading]: false,
  [ODM.StateVars.KeyDescriptionsAlternativesToHospitalCover]: {
    alternativesToHospitalCover: 'Description',
  },
  [ODM.StateVars.KeyDescriptionCoPayments]: {
    procedureSpecific: 'Procedure Specific',
    penalty: 'Penalty',
  },
  [ODM.StateVars.KeyDescriptionDayToDayExpenses]: {
    aboveThresholdBenefitsAndRestrictions: 'Above-Threshold Benefits and Restrictions',
    summary: 'Summary',
    savingsAccount: 'Savings Account',
    selfPaymentGap: 'Self-Payment Gap',
  },
  [ODM.StateVars.KeyDescriptionDayToDaySearchByCategory]: {
    summary: 'Summary',
    gpConsultations: 'GP Consultations',
    specialistConsultations: 'Specialist Consultations',
    medication: 'Medication',
    pathologyAndBasicRadiology: 'Pathology and Basic Radiology',
    dentistry: 'Dentistry',
    optometry: 'Optometry',
    hearingAids: 'Hearing Aids',
    mentalMatters: 'Mental Matters',
    alliedAndAlternativeHealthcareProfessionals: 'Allied and Alternative Healthcare Professionals',
  },
  [ODM.StateVars.KeyDescriptionEmergencyAndInternational]: {
    casualtyCover: 'Casualty Cover',
    internationalCover: 'International Cover',
    ambulanceAndEmergency: 'Ambulance and Emergency Services',
  },
  [ODM.StateVars.KeyDescriptionGeneric]: {
    description: 'Description',
  },
  [ODM.StateVars.KeyDescriptionHospitalCover]: {
    hospitalAccount: 'Hospital Account',
    inHospitalSpecialistRates: 'In-Hospital Specialist Rates',
    basicRadiologyAndPathology: 'Basic Radiology and Pathology',
    refractiveSurgery: 'Refractive Surgery',
    oncology: 'Oncology',
    chronicRenalDialysis: 'Chronic Renal Dialysis',
    takeOutMedication: 'Take-out Medication',
    spinalSurgery: 'Spinal Surgery',
    inHospitalDentistry: 'In-Hospital Dentistry',
    jointReplacements: 'Joint Replacements',
    confinementBabyBirth: 'Confinement (Baby birth)',
    mentalMatters: 'Mental Matters',
    biologicalAndSpecialisedMedication: 'Biological and Specialised Medication',
  },
  [ODM.StateVars.KeyDescriptionLineItems]: {
    networkAndInHospitalSpecialistFees: 'Network and In-Hospital Specialist Fees',
    savings: 'Savings',
    nonTransferrableSchemeBenefits: 'Non-Transferrable Scheme Benefits',
    chronic: 'Chronic',
    savingsPlusPooledBenefits: 'Savings Plus Pooled Benefits',
    nonTransferrablePooledBenefits: 'Non-Transferrable Pooled Benefits',
  },
  [ODM.StateVars.KeyDescriptionMaternity]: {
    babyAndChildBenefits: 'Baby and Child Benefits',
    outOfHospitalPregnancyBenefits: 'Out-of-Hospital Pregnancy Benefits',
    preventativeCareBenefits: 'Preventative Care Benefits',
    vaccines: 'Vaccines',
  },
  [ODM.StateVars.KeyDescriptionProsthesisAndMedicalAppliances]: {
    externalProsthesisAndMedicalAppliances: 'External Prosthesis and Medical Appliances',
    internalProsthesis: 'Internal Prosthesis',
  },
  [ODM.StateVars.Ordering]: [
    'hospitalCover',
    'alternativesToHospitalCover',
    'dayToDaySearchByCategory',
    'maternityAndPreventativeCare',
    'coPayments',
    'specialisedRadiology',
    'scopes',
    'mentalMatters',
    'prosthesisAndMedicalAppliances',
    'emergencyAndInternationalCover',
    'chronicCover',
    'lineItems',
  ],
  [ODM.StateVars.SlabDescriptions]: {
    alternativesToHospitalCover: {
      name: 'Alternatives to Hospital Cover',
      sections: {
        alternativesToHospitalCover: 'Description',
      },
    },
    chronicCover: {
      name: 'Chronic Cover',
      sections: {
        description: 'Description',
      },
    },
    coPayments: {
      name: 'Co-payments (for major procedures)',
      sections: {
        procedureSpecific: 'Procedure Specific Co-payments',
        penalty: 'Penalty Co-payments',
      },
    },
    dayToDayExpenses: {
      name: 'Day-to-Day Expenses',
      sections: {
        aboveThresholdBenefitsAndRestrictions: 'Above-Threshold Benefits and Restrictions',
        summary: 'Summary',
        savingsAccount: 'Savings Account',
        selfPaymentGap: 'Self-Payment Gap',
      },
    },
    dayToDaySearchByCategory: {
      name: 'Day-to-day Benefits',
      sections: {
        summary: 'Summary',
        gpConsultations: 'GP Consultations',
        specialistConsultations: 'Specialist Consultations',
        medication: 'Acute Medication',
        contraceptives: 'Contraceptives',
        pathologyAndBasicRadiology: 'Pathology and Basic Radiology',
        dentistry: 'Dentistry',
        optometry: 'Optometry',
        hearingAids: 'Hearing Aids',
        alliedAndAlternativeHealthcareProfessionals: 'Allied and Alternative Healthcare Professionals',
      },
    },
    emergencyAndInternationalCover: {
      name: 'Emergency Services and International Cover',
      sections: {
        casualtyCover: 'Casualty Cover',
        internationalCover: 'International Cover',
        ambulanceAndEmergency: 'Ambulance and Emergency Services',
      },
    },
    gapCover: {
      name: 'Reasons for Gap Cover',
      sections: {
        description: 'Description',
      },
    },
    hospitalCover: {
      name: 'Hospital Cover',
      sections: {
        hospitalAccount: 'Hospital Account',
        inHospitalSpecialistRates: 'In-hospital Specialist Rates',
        basicRadiologyAndPathology: 'Basic Radiology and Pathology',
        takeOutMedication: 'Take-out Medication',
        oncology: 'Oncology',
        jointReplacements: 'Joint Replacements',
        spinalSurgery: 'Spinal Surgery',
        inHospitalDentistry: 'In-hospital Dentistry',
        confinementBabyBirth: 'Confinement',
        refractiveSurgery: 'Refractive Surgery',
        chronicRenalDialysis: 'Chronic Renal Dialysis',
        biologicalAndSpecialisedMedication: 'Biological and Specialised Medication',
      },
    },
    maternityAndPreventativeCare: {
      name: 'Maternity, Preventative, and Early Detection Benefits',
      sections: {
        preventativeCareBenefits: 'Screening and Early Detection Benefits',
        outOfHospitalPregnancyBenefits: 'Out-of-Hospital Maternity Benefits',
        babyAndChildBenefits: 'Baby- and Child Benefits',
        vaccines: 'Vaccines',
        supportingWellness: 'Supporting Wellness',
      },
    },
    mentalMatters: {
      name: 'Mental Matters',
      sections: {
        description: 'Description',
      },
    },
    prosthesisAndMedicalAppliances: {
      name: 'Prosthesis and Medical Appliances',
      sections: {
        externalProsthesisAndMedicalAppliances: 'External Prosthesis and Medical Appliances',
        internalProsthesis: 'Internal Prosthesis',
      },
    },
    scopes: {
      name: 'Scopes',
      sections: {
        description: 'Description',
      },
    },
    specialisedRadiology: {
      name: 'Specialised Radiology',
      sections: {
        description: 'Description',
      },
    },
  },
});

const getters = {
  [ODM.Getters.ObjectKeys]: (stateP) => {
    if (!Object.keys(stateP[ODM.StateVars.Data]).length) {
      return null;
    }
    const firstResult = stateP[ODM.StateVars.Data][Object.keys(stateP[ODM.StateVars.Data])[0]];
    const entries = Object.entries(firstResult);
    return entries
      .filter((e) => e[0] !== 'lineItems')
      .map((e) => ({
        slab: e[0],
        sections: Object.keys(e[1]),
      }));
  },
};

const SetStateVarToValue = (stateVar) => (stateP, value) => {
  const localState = stateP;
  localState[stateVar] = value;
};

const mutations = {
  [Mutations.Reset]: (stateP) => Object.assign(stateP, initialState()),
  [Mutations.SetData]: SetStateVarToValue(ODM.StateVars.Data),
  [Mutations.SetIsLoading]: SetStateVarToValue(ODM.StateVars.IsLoading),
};

const state = initialState();

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
