import axios from 'axios';

import PrimeVue from 'primevue/config';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Card from 'primevue/card';
import CascadeSelect from 'primevue/cascadeselect';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Menubar from 'primevue/menubar';
import MultiSelect from 'primevue/multiselect';
import Panel from 'primevue/panel';
import ProgressSpinner from 'primevue/progressspinner';
import SelectButton from 'primevue/selectbutton';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import TextArea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';

import { createApp } from 'vue';

import Vue3Tour from 'vue3-tour';

import '@/assets/styles/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'vue3-tour/dist/vue3-tour.css';

import { setupAuth } from '@/auth';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';

import App from '@/App.vue';

import authConfig from '../auth_config.json';

const app = createApp(App);

function callbackRedirect(appState) {
  router.push((appState && appState.targetUrl) ? appState.targetUrl : '/');
}

const location = window.location;
const url = `${location.protocol}//${location.host}/`;
const localAuthConfig = {
  ...authConfig,
  client_id: process.env.VUE_APP_CLIENT_ID,
  domain: process.env.VUE_APP_DOMAIN,
  redirect_uri: url,
};

setupAuth(localAuthConfig, callbackRedirect)
  .then((auth) => {
    app
      .use(auth)
      .use(store)
      .use(router)
      .use(PrimeVue)
      .use(ToastService)
      .use(Vue3Tour)
      .provide('tours', app.config.globalProperties.$tours)
      .provide('auth', app.config.globalProperties.$auth);

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 403) {
          router.push({
            name: 'AccountUnauthorised',
          });
        }
        return Promise.reject(error);
      },
    );

    app.component('PAccordion', Accordion);
    app.component('PAccordionTab', AccordionTab);
    app.component('PButton', Button);
    app.component('PCard', Card);
    app.component('PCheckbox', Checkbox);
    app.component('PCascadeSelect', CascadeSelect);
    app.component('PColumn', Column);
    app.component('PDialog', Dialog);
    app.component('PDropdown', Dropdown);
    app.component('PFieldset', Fieldset);
    app.component('PInputMask', InputMask);
    app.component('PInputNumber', InputNumber);
    app.component('PInputText', InputText);
    app.component('PMenubar', Menubar);
    app.component('PMultiSelect', MultiSelect);
    app.component('PPanel', Panel);
    app.component('PProgressSpinner', ProgressSpinner);
    app.component('PSelectButton', SelectButton);
    app.component('PTabPanel', TabPanel);
    app.component('PTabView', TabView);
    app.component('PTextArea', TextArea);
    app.component('PToast', Toast);
    app.component('PTooltip', Tooltip);

    app.mount('#app');
  });
