import { createRouter, createWebHistory } from 'vue-router';
import { authPlugin, routeGuard } from '@/auth';

const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: () => ({
      name: (authPlugin.isAuthenticated.value) ? 'AuthenticatedHome' : 'UnauthenticatedHome',
    }),
  },
  {
    component: () => import('@/views/TheAccountUnauthorisedView.vue'),
    path: '/account/unauthorised',
    name: 'AccountUnauthorised',
  },
  {
    path: '/home/authenticated',
    name: 'AuthenticatedHome',
    component: () => import('@/views/TheAuthenticatedHome.vue'),
    beforeEnter: routeGuard,
  },
  {
    path: '/welcome',
    name: 'UnauthenticatedHome',
    component: () => import('@/views/TheUnauthenticatedHome.vue'),
  },
  {
    path: '/comparison/:id',
    name: 'Comparison',
    component: () => import('@/views/TheComparisonView.vue'),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
