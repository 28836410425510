export default {
  ComparisonMailDialog: {
    Mutations: {
      SetIsVisible: 'SetIsVisible',
    },
    Name: 'ComparisonMailDialog',
    StateVars: {
      IsVisible: 'isVisible',
    },
  },
  Mutations: {
    Reset: 'Reset',
    SetData: 'SetData',
    SetIsLoading: 'SetIsLoading',
  },
  QuoteModule: {
    ActionAddDependant: 'AddDependant',
    ActionChangeDependants: 'ChangeDependants',
    ActionChangeIncome: 'ChangeIncome',
    ActionChangeMainMember: 'ChangeMainMember',
    ActionChangePersonalInformation: 'ChangePersonalInformation',
    ActionLoad: 'Load',
    ActionReinitialise: 'Reinitialise',
    ActionRemoveDependant: 'RemoveDependant',
    GetterHasSelectedOptions: 'GetterHasSelectedOptions',
    GetterSelectedOptionDetails: 'GetterSelectedOptionDetails',
    MutationSetBasicInformation: 'SetBasicInformation',
    MutationSetBracketCeiling: 'SetBracketCeiling',
    MutationSetBracketFloor: 'SetBracketFloor',
    MutationSetDependants: 'SetDependants',
    MutationSetLateJoinerPenalty: 'SetLateJoinerPenalty',
    MutationSetIncome: 'SetIncome',
    MutationSetOptions: 'SetOptions',
    MutationSetSelectedOptions: 'SetSelectedOptions',
    MutationSetSelectedSchemes: 'SetSelectedSchemes',
    Name: 'Quote',
    StateVars: {
      Dependants: 'dependants',
      Income: 'income',
      LateJoinerPenalty: 'lateJoinerPenalty',
      SelectedOptions: 'selectedOptions',
    },
  },
  OptionDetailsModule: {
    ActionLoad: 'Load',
    Getters: {
      ObjectKeys: 'objectKeys',
    },
    Name: 'OptionDetails',
    StateVars: {
      Data: 'data',
      IsLoading: 'isLoading',
      KeyDescriptionsAlternativesToHospitalCover: 'keyDescriptionsAlternativesToHospitalCover',
      KeyDescriptionCoPayments: 'keyDescriptionCoPayments',
      KeyDescriptionDayToDayExpenses: 'keyDescriptionDayToDayExpenses',
      KeyDescriptionDayToDaySearchByCategory: 'keyDescriptionDayToDaySearchByCategory',
      KeyDescriptionEmergencyAndInternational: 'keyDescriptionEmergencyAndInternational',
      KeyDescriptionGeneric: 'keyDescriptionGeneric',
      KeyDescriptionHospitalCover: 'keyDescriptionHospitalCover',
      KeyDescriptionLineItems: 'keyDescriptionLineItems',
      KeyDescriptionMaternity: 'keyDescriptionMaternity',
      KeyDescriptionProsthesisAndMedicalAppliances: 'keyDescriptionProsthesisAndMedicalAppliances',
      Ordering: 'ordering',
      SlabDescriptions: 'SlabDescriptions',
    },
  },
  OptionsModule: {
    ActionLoad: 'Load',
    Name: 'Options',
    StateVars: {
      Data: 'data',
      IsLoading: 'isLoading',
    },
  },
  ReportModule: {
    Mutations: {
      SetCollapsedSlabs: 'SetCollapsedSlabs',
      SetFocusedOption: 'SetFocusedOption',
    },
    Name: 'Report',
    StateVars: {
      CollapsedSlabs: 'collapsedSlabs',
      FocusedOption: 'focusedOption',
    },
  },
  SchemesModule: {
    ActionLoad: 'Load',
    Name: 'Schemes',
    StateVars: {
      Data: 'data',
      IsLoading: 'isLoading',
    },
  },
  SharedComparisonModule: {
    ActionCreate: 'Create',
    ActionLoad: 'Load',
    Name: 'SharedComparison',
    StateVars: {
      Data: 'data',
      IsLoading: 'isLoading',
    },
  },
  ToursModule: {
    Mutations: {
      SetHasTourRun: 'SetHasTourRun',
      SetStepNumber: 'SetStepNumber',
    },
    Name: 'ToursModule',
    StateVars: {
      HasTourRun: 'hasTourRun',
      StepNumber: 'stepNumber',
    },
  },
};
