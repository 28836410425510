import { createStore } from 'vuex';

import constants from '@/store/constants';
import ComparisonMailDialog from '@/store/modules/ComparisonMailDialog';
import OptionDetails from '@/store/modules/OptionDetails';
import Options from '@/store/modules/Options';
import Quote from '@/store/modules/Quote';
import Report from '@/store/modules/Report';
import Schemes from '@/store/modules/Schemes';
import SharedComparison from '@/store/modules/SharedComparison';
import ToursModule from '@/store/modules/Tour';

export default createStore({
  modules: {
    [constants.ComparisonMailDialog.Name]: ComparisonMailDialog,
    [constants.OptionDetailsModule.Name]: OptionDetails,
    [constants.OptionsModule.Name]: Options,
    [constants.QuoteModule.Name]: Quote,
    [constants.ReportModule.Name]: Report,
    [constants.SchemesModule.Name]: Schemes,
    [constants.SharedComparisonModule.Name]: SharedComparison,
    [constants.ToursModule.Name]: ToursModule,
  },
});
