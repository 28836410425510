import constants from '@/store/constants';

const {
  ToursModule: TMod,
} = constants;

const getLocalStorageKeyName = (variableName) => `tour.${variableName}`;

const getFromLocalStorage = (variableName) => JSON.parse(
  window.localStorage.getItem(
    getLocalStorageKeyName(variableName)));

const setLocalStorage = (variableName, value) => {
  window.localStorage.setItem(getLocalStorageKeyName(variableName), JSON.stringify(value));
};

setLocalStorage(TMod.StateVars.StepNumber, null);

const initialState = () => ({
  [TMod.StateVars.HasTourRun]: getFromLocalStorage(TMod.StateVars.HasTourRun),
  [TMod.StateVars.StepNumber]: getFromLocalStorage(TMod.StateVars.StepNumber),
});

const SetStateVarToValue = (stateVar) => (stateP, value) => {
  setLocalStorage(stateVar, value);
  const localState = stateP;
  localState[stateVar] = value;
};

const mutations = {
  [TMod.Mutations.SetHasTourRun]: SetStateVarToValue(TMod.StateVars.HasTourRun),
  [TMod.Mutations.SetStepNumber]: SetStateVarToValue(TMod.StateVars.StepNumber),
};

const state = initialState();

export default {
  mutations,
  namespaced: true,
  state,
};
