import constants from '@/store/constants';

const {
  ComparisonMailDialog: {
    Mutations,
    StateVars,
  },
} = constants;

const initialState = () => ({
  [StateVars.IsVisible]: false,
});

const state = initialState();

const SetStateVarToValue = (stateVar) => (stateP, value) => {
  const localState = stateP;
  localState[stateVar] = value;
};

const mutations = {
  [Mutations.SetIsVisible]: SetStateVarToValue(StateVars.IsVisible),
};

export default {
  mutations,
  namespaced: true,
  state,
};
