import axios from 'axios';
import { authPlugin } from '@/auth';

import constants from '@/store/constants';
import quotePayloadFactory from '@/store/quotePayloadFactory';

const Endpoint = `${process.env.VUE_APP_API}/Quotes`;

const Mutations = {
  SetContactnumber: 'SetContactnumber',
  SetDependants: 'SetDependants',
  SetEmail: 'SetEmail',
  SetIsLoading: 'SetIsLoading',
  SetLateJoinerPenalty: 'SetLateJoinerPenalty',
  SetName: 'SetName',
  SetSchemes: 'SetSchemes',
  SetSurname: 'SetSurname',
  SetTitle: 'SetTitle',
};

const StateVars = {
  bracketFloor: 'bracketFloor',
  bracketCeiling: 'bracketCeiling',
  contactNumber: 'contactNumber',
  dependantChildCategoryOptions: 'dependantChildCategoryOptions',
  dependantOptions: 'dependantOptions',
  email: 'email',
  isLoading: 'isLoading',
  lateJoinerPenaltyOptions: 'lateJoinerPenaltyOptions',
  options: 'options',
  name: 'name',
  schemes: 'schemes',
  selectedSchemes: 'selectedSchemes',
  surname: 'surname',
  title: 'title',
  titleOptions: 'titleOptions',
};

const actions = {
  [constants.QuoteModule.ActionAddDependant]: async (context, payload) => {
    const dependants = [...context.state[constants.QuoteModule.StateVars.Dependants]];
    dependants.push(payload);
    context.commit(constants.QuoteModule.MutationSetDependants, dependants);
    context.commit(Mutations.SetIsLoading, true);
    try {
      await context.dispatch(constants.QuoteModule.ActionLoad);
    } catch (error) {
      throw new Error('Could not change dependants');
    } finally {
      context.commit(Mutations.SetIsLoading, false);
    }
  },
  [constants.QuoteModule.ActionChangeMainMember]: async (context, payload) => {
    if (!payload) {
      throw Error('Parameter "payload" is Required');
    }
    context.commit(constants.QuoteModule.MutationSetLateJoinerPenalty, payload.lateJoinerPenalty);
    context.commit(constants.QuoteModule.MutationSetIncome, payload.income);

    try {
      await context.dispatch(constants.QuoteModule.ActionLoad);
    } catch (error) {
      throw new Error('Could not change inputs');
    }
  },
  [constants.QuoteModule.ActionLoad]: async (context) => {
    context.commit(Mutations.SetIsLoading, true);
    try {
      const token = await authPlugin.getTokenSilently();

      const { data } = await axios.post(
        Endpoint,
        quotePayloadFactory.Generate(
          context.state[constants.QuoteModule.StateVars.LateJoinerPenalty],
          context.state[constants.QuoteModule.StateVars.Income],
          context.state[constants.QuoteModule.StateVars.Dependants],
        ),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      context.commit(constants.QuoteModule.MutationSetOptions, data);
    } catch (error) {
      throw new Error('Could not load Quotes');
    } finally {
      context.commit(Mutations.SetIsLoading, false);
    }
  },
  [constants.QuoteModule.ActionReinitialise]: async (context) => {
    context.commit(constants.Mutations.Reset);
    await context.dispatch(constants.QuoteModule.ActionLoad);
  },
  [constants.QuoteModule.ActionRemoveDependant]: async (context, payload) => {
    let dependants = [...context.state[constants.QuoteModule.StateVars.Dependants]];
    dependants = dependants.filter((d) => d.id !== payload);
    context.commit(constants.QuoteModule.MutationSetDependants, dependants);
    context.commit(Mutations.SetIsLoading, true);
    try {
      await context.dispatch(constants.QuoteModule.ActionLoad);
    } catch (error) {
      throw new Error('Could not change dependants');
    } finally {
      context.commit(Mutations.SetIsLoading, false);
    }
  },
};

const initialState = () => ({
  [StateVars.bracketFloor]: null,
  [StateVars.bracketCeiling]: null,
  [StateVars.contactNumber]: null,
  [StateVars.dependantChildCategoryOptions]: [
    'Disabled',
    'Financially Dependant',
    'Full time Student',
  ],
  [StateVars.dependantOptions]: [
    {
      name: 'Adult',
      value: 'Adult',
    },
    {
      name: 'Child',
      value: 'Child',
    },
  ],
  [constants.QuoteModule.StateVars.Dependants]: [],
  [StateVars.email]: null,
  [constants.QuoteModule.StateVars.Income]: null,
  [StateVars.isLoading]: false,
  [constants.QuoteModule.StateVars.LateJoinerPenalty]: 1,
  [StateVars.lateJoinerPenaltyOptions]: [
    {
      id: 1,
      label: 'None',
      description: 'No Late Joiner Penalty',
    },
    {
      id: 2,
      label: '5% (0-4 years)',
      description: '5% Late Joiner Penalty',
    },
    {
      id: 3,
      label: '25% (5-14 years)',
      description: '25% Late Joiner Penalty',
    },
    {
      id: 4,
      label: '50% (15-24 years)',
      description: '50% Late Joiner Penalty',
    },
    {
      id: 5,
      label: '75% (25+ years)',
      description: '75% Late Joiner Penalty',
    },
  ],
  [StateVars.options]: [],
  [StateVars.name]: null,
  [StateVars.schemes]: [],
  [constants.QuoteModule.StateVars.SelectedOptions]: [],
  [StateVars.selectedSchemes]: [],
  [StateVars.surname]: null,
  [StateVars.title]: null,
  [StateVars.titleOptions]: [
    'Mr',
    'Mrs',
    'Ms',
    'Dr',
    'Other',
  ],
});

const getters = {
  [constants.QuoteModule.GetterSelectedOptionDetails]: (stateP) => (stateP[StateVars.options] || [])
    .filter((opt) => stateP[constants.QuoteModule.StateVars.SelectedOptions]
      .some((so) => so === opt.id)),
  [constants.QuoteModule.GetterHasSelectedOptions]: (stateP) => !!(stateP.selectedOptions?.length),
};

const SetStateVarToValue = (stateVar) => (stateP, value) => {
  const localState = stateP;
  localState[stateVar] = value;
};

const mutations = {
  [constants.Mutations.Reset]: (stateP) => Object.assign(stateP, initialState()),
  [constants.QuoteModule.MutationSetBasicInformation]: (stateP, payload) => {
    const localState = stateP;
    localState[StateVars.contactNumber] = payload.contactNumber;
    localState[StateVars.email] = payload.email;
    localState[StateVars.name] = payload.name;
    localState[StateVars.surname] = payload.surname;
    localState[StateVars.title] = payload.title;
  },
  [constants.QuoteModule.MutationSetBracketCeiling]: SetStateVarToValue(StateVars.bracketCeiling),
  [constants.QuoteModule.MutationSetBracketFloor]: SetStateVarToValue(StateVars.bracketFloor),
  [constants.QuoteModule.MutationSetOptions]: SetStateVarToValue(StateVars.options),
  [constants.QuoteModule.MutationSetSelectedOptions]:
    SetStateVarToValue(constants.QuoteModule.StateVars.SelectedOptions),
  [constants.QuoteModule.MutationSetSelectedSchemes]: SetStateVarToValue(StateVars.selectedSchemes),
  [Mutations.SetContactnumber]: SetStateVarToValue(StateVars.contactNumber),
  [constants.QuoteModule.MutationSetDependants]:
    SetStateVarToValue(constants.QuoteModule.StateVars.Dependants),
  [Mutations.SetEmail]: SetStateVarToValue(StateVars.email),
  [constants.QuoteModule.MutationSetIncome]:
    SetStateVarToValue(constants.QuoteModule.StateVars.Income),
  [Mutations.SetIsLoading]: SetStateVarToValue(StateVars.isLoading),
  [constants.QuoteModule.MutationSetLateJoinerPenalty]:
    SetStateVarToValue(constants.QuoteModule.StateVars.LateJoinerPenalty),
  [Mutations.SetName]: SetStateVarToValue(StateVars.name),
  [Mutations.SetSchemes]: SetStateVarToValue(StateVars.schemes),
  [Mutations.SetSurname]: SetStateVarToValue(StateVars.surname),
  [Mutations.SetTitle]: SetStateVarToValue(StateVars.title),
};

const state = initialState();

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
