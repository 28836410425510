import axios from 'axios';
import { authPlugin } from '@/auth';

import constants from '@/store/constants';

const {
  SchemesModule: SMod,
} = constants;

const Endpoint = `${process.env.VUE_APP_API}/Schemes`;

const Mutations = {
  SetData: 'SetData',
  SetIsLoading: 'SetIsLoading',
};

const initialState = () => ({
  [SMod.StateVars.Data]: null,
  [SMod.StateVars.IsLoading]: false,
});

const actions = {
  [SMod.ActionLoad]: async (context) => {
    context.commit(Mutations.SetIsLoading, true);
    try {
      const token = await authPlugin.getTokenSilently();

      const { data } = await axios.get(
        Endpoint,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      context.commit(Mutations.SetData, data);
    } catch (error) {
      throw new Error('Could not load Schemes');
    } finally {
      context.commit(Mutations.SetIsLoading, false);
    }
  },
};

const SetStateVarToValue = (stateVar) => (stateP, value) => {
  const localState = stateP;
  localState[stateVar] = value;
};

const mutations = {
  [Mutations.SetData]: SetStateVarToValue(SMod.StateVars.Data),
  [Mutations.SetIsLoading]: SetStateVarToValue(SMod.StateVars.IsLoading),
};

const state = initialState();

export default {
  actions,
  mutations,
  namespaced: true,
  state,
};
